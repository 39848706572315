import { BSONConverter } from 'mongodb-bson-types';
import { Status } from '../status/Status';
import { newTitle, Title } from '../title/Title';
import { SlideshowSettings } from './SlideshowSettings';

export interface Topic extends ReturnType<typeof newTopic> {}

export const newTopic = (fetchedTopic: Partial<TopicConstructorParams>) => {
	const _topic = new _Topic(fetchedTopic); //for data conversions (_id -> id, etc.)
	const topic = JSON.parse(JSON.stringify(_topic)); // serialized-converted Topic
	return topic;
};

interface ExhibitLocation {
	latitude: number;
	logitude: number;
	radius: number;
	customeMessage: string;
}

interface TopicConstructorParams {
	id: string;
	_id: any;
	areaId: string; // back-compat
	sectionId: string;
	name: Title[];
	status: Status;
	content: string[];
	qr: string;
	qrUpdatedAt: { $date: { $numberLong: string } };
	enableSharing: boolean;
	organizationId: string;
	slideshowSettings: SlideshowSettings;
	background: string;
	backgroundImageName: string;
	exhibitImage: string;
	loc: ExhibitLocation;
	createNewEntity?: boolean;
}

export class _Topic {
	public id: string = '';
	public sectionId: string = '';
	public name: Title[] = [newTitle({})];
	public status: Status = Status.Hidden;
	public qr: string = '';
	public qrUpdatedAt: { $date: { $numberLong: string } } = null;
	public content: string[] = [];
	public enableSharing: boolean = false;
	public organizationId: string = '';
	public slideshowSettings: SlideshowSettings = new SlideshowSettings();
	public background: string = null;
	public backgroundImageName: string = null;
	public exhibitImage: string = null;
	public loc: ExhibitLocation | null = null;
	public createNewEntity: boolean = null;

	constructor(data?: Partial<TopicConstructorParams>) {
		if (!!data) {
			this.createNewEntity = !!data.createNewEntity || null;
			this.id = data.id || this.id;
			if (data._id) {
				this.id = BSONConverter.objectId(data._id);
			}
			//this kind of stuff is pure madness, my apologies
			this.sectionId = data.areaId || this.sectionId;
			this.sectionId = data.sectionId || this.sectionId;
			// this.name = data.name || this.name;
			if (data.name && Array.isArray(data.name)) {
				this.name = [...data.name];
			}
			this.status = data.status || this.status;
			this.qr = data.qr || this.qr;
			this.qrUpdatedAt = data.qrUpdatedAt || this.qrUpdatedAt;
			this.content = data.content || this.content;
			this.enableSharing = data.enableSharing || this.enableSharing;
			this.organizationId = data.organizationId || this.organizationId;
			if (!!data.slideshowSettings) {
				this.slideshowSettings = new SlideshowSettings(data.slideshowSettings);
			}
			if (data.background) {
				this.background = data.background;
			}
			this.backgroundImageName = data.backgroundImageName || this.backgroundImageName;
			this.exhibitImage = data.exhibitImage || this.exhibitImage;
			this.loc = data.loc || this.loc;
		}
	}
}
