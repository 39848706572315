import Typography from '@material-ui/core/Typography';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@mui/material/Box';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useLayoutEffect, useState } from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { changeQrLogo, changeQrPrimaryColor, changeQrSecondaryColor } from '../../../store/slices/locationSlice';
import { getQrs } from '../../../store/slices/topicSlice';
import { colors } from '../../../theme/palette';
import { CLOSED_NOT_SCANNABLE, CLOSED_QR_DIALOG, CLOSED_QR_HOVER } from '../../../util/constants';
import { FlatButton } from '../../Buttons/FlatButton';
import { LiiingoDismissableTip } from '../../LiiingoDismissableTip';
import { LiiingoTempDrawer } from '../../LiiingoTempDrawer';
import { LiiingoPreviewQrCodeSvg } from './LiiingoDemoQrCodeSvg';
import { QRColorPickerPopover } from './QRColorPickerPopover';
import { QRDialog } from './QRDialog';
import { QRLogoOption } from './QRLogoOption';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		editorBox: {
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'column',
			width: 547,
			height: '100%',
			marginLeft: 10,
			marginRight: 10,
		},
		buttonBox: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			height: 80,
			background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #92A0AC',
			marginLeft: -10,
			marginRight: -10,
			marginTop: 40,
		},
		button: {
			height: 40,
			marginRight: 30,
		},
		previewBox: {
			display: 'flex',
			justifyContent: 'space-between',
		},
		desc: {
			width: '75%',
			marginBottom: 40,
			color: '#181B20',
		},
		preview: {
			width: 254,
		},
		colorBox: {
			display: 'flex',
			justifyContent: 'space-between',
			marginTop: 10,
		},
		menu: {
			display: 'flex',
			flexDirection: 'column',
			position: 'relative',
			left: 0,
			top: 0,
		},
		optionBox: {
			overflowY: 'auto',
			position: 'relative',
		},
		alert: {
			margin: 8,
			backgroundColor: colors.hotPurpleAccent20,
		},
		icon: {
			color: colors.hotPurpleAccent,
		},
		dismissableTip: {
			marginTop: 40,
		},
		link: {
			color: 'blue',
			textDecoration: 'none',
			fontWeight: 700,
		},
	})
);

export type QREditorProps = {
	open: boolean;
	logo: string;
	primary: string;
	secondary: string;
	setOpen: (open: boolean) => void;
	handleUpdate: () => void;
};

export const QREditor = (props: QREditorProps) => {
	const { open, logo, primary, secondary, setOpen, handleUpdate } = {
		...props,
	};
	const [previewLogo, setPreviewLogo] = useState(logo);
	const [logoName, setLogoName] = useState('');
	const [primaryColor, setPrimaryColor] = useState(primary);
	const [secondaryColor, setSecondaryColor] = useState(secondary);
	const [openDialog, setOpenDialog] = useState(false);
	const [primaryError, setPrimaryError] = useState(false);
	const [secondaryError, setSecondaryError] = useState(false);
	const [isDrag, setIsDrag] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	const dispatch = useAppDispatch();

	const changeLogo = bindActionCreators(changeQrLogo, dispatch);
	const changePrimary = bindActionCreators(changeQrPrimaryColor, dispatch);
	const changeSecondary = bindActionCreators(changeQrSecondaryColor, dispatch);
	const getNewQrs = bindActionCreators(getQrs, dispatch);

	const classes = useStyles();
	const resetEditor = () => {
		setPrimaryColor(primary);
		setSecondaryColor(secondary);
		setPreviewLogo(logo);
		setShowAlert(false);
	};

	const resetLogo = () => {
		setPreviewLogo(null);
		setShowAlert(false);
	};

	const handleOpen = (open: boolean) => {
		let isEdited = false;
		if (primary !== primaryColor) isEdited = true;
		if (secondary !== secondaryColor) isEdited = true;
		if (logo !== previewLogo) isEdited = true;
		if (!open && !localStorage.getItem(CLOSED_QR_DIALOG) && isEdited) {
			setOpenDialog(true);
		} else {
			setOpen(open);
			resetEditor();
		}
	};

	const handleSave = () => {
		changeLogo({ name: logoName, url: previewLogo });
		changePrimary(primaryColor);
		changeSecondary(secondaryColor);
		getNewQrs(); //this will save the location then request new QRs for all pages
		setOpen(false);
		handleUpdate();
	};

	const useWindowSize = () => {
		const [size, setSize] = useState(0);

		useLayoutEffect(() => {
			function updateSize() {
				setSize(window.innerHeight);
			}
			window.addEventListener('resize', updateSize);
			updateSize();
			return () => window.removeEventListener('resize', updateSize);
		}, []);
		return size;
	};

	const height = useWindowSize();

	const handleDragStart = () => {
		if (!isDrag) setIsDrag(true);
	};

	const handleDragStop = () => {
		if (isDrag) setIsDrag(false);
	};

	window.addEventListener('dragover', handleDragStart, false);
	window.addEventListener('dragleave', handleDragStop, false);
	window.addEventListener('drop', handleDragStop, false);

	return (
		<LiiingoTempDrawer title="Design Your QR Code" open={open} setOpen={handleOpen}>
			<QRDialog
				open={openDialog}
				onClose={() => {
					setOpenDialog(false);
				}}
				closeDrawer={() => {
					setOpen(false);
					resetEditor();
				}}
			/>
			<Box className={classes.editorBox}>
				<Box className={classes.menu}>
					<Typography className={classes.desc} variant="body2">
						Add a logo and colors to make your QR code recognizable to your audience. For best results,
						upload your logo as an SVG. <br></br>
						<a
							className={classes.link}
							href="https://support.liiingo.com/question-category/share"
							target="_blank"
							rel="noreferrer"
						>
							Learn more in our Help Center.{' '}
						</a>
					</Typography>
					<Box component="div" className={classes.optionBox} height={height - 300}>
						<Box className={classes.colorBox}>
							{/**
							 * Color Pickers
							 */}
							<QRColorPickerPopover
								setColor={setPrimaryColor}
								setError={setPrimaryError}
								id="primaryColor"
								name="customQrCodeColors.primary"
								label="Primary Color"
								color={primaryColor ?? '#000000'}
							/>
							<QRColorPickerPopover
								setColor={setSecondaryColor}
								setError={setSecondaryError}
								id="secondaryColor"
								name="customQrCodeColors.secondary"
								label="Secondary Color"
								color={secondaryColor ?? '#000000'}
							/>
						</Box>
						<Box className={classes.previewBox}>
							<div className={classes.preview} style={{ height: 254 }}>
								<QRLogoOption
									imageUrl={previewLogo}
									setImagePreview={(file: File) => {
										setShowAlert(true);
										setLogoName(file.name);
										setPreviewLogo(URL.createObjectURL(file));
									}}
									isDrag={isDrag}
								/>
							</div>
							<div className={classes.preview} style={{ height: 254 }}>
								<LiiingoPreviewQrCodeSvg
									primaryColor={primaryColor}
									secondaryColor={secondaryColor}
									customQrLogoUrl={previewLogo}
									resetLogo={resetLogo}
								/>
							</div>
						</Box>
						<div className={classes.dismissableTip}>
							{showAlert && (
								<LiiingoDismissableTip name={CLOSED_QR_HOVER}>
									<Typography variant="body2">
										Hover on the logo in the QR code preview, then click the trash icon to revert to
										the default logo.
									</Typography>
								</LiiingoDismissableTip>
							)}

							<LiiingoDismissableTip name={CLOSED_NOT_SCANNABLE}>
								<div>
									<Typography variant="body2">
										This QR code preview is not scannable. Finish editing your QR code, then scan
										from the Dashboard.
									</Typography>
								</div>
							</LiiingoDismissableTip>
						</div>
					</Box>
				</Box>
				<Box className={classes.buttonBox}>
					<FlatButton className={classes.button} onClick={() => handleOpen(false)}>
						Cancel
					</FlatButton>
					<FlatButton
						disabled={primaryError || secondaryError}
						className={classes.button}
						variant="contained"
						color="primary"
						onClick={handleSave}
					>
						Save
					</FlatButton>
				</Box>
			</Box>
		</LiiingoTempDrawer>
	);
};
